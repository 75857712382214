import React from "react"
import { Link } from "gatsby"
import GatsbyLogo from "../assets/svg/gatsby.inline.svg"
import Menu from "./menu"
import MobileMenu from "./mobile-menu"
import { Container, Row, Navbar } from 'react-bootstrap';
import "../assets/scss/regions/header.scss";
import '../assets/scss/regions/hamburger.scss';

export default () => (

<header className="site-header">
  <Container>
    <Row>
      <Navbar>
        <div className="logo">
          <Link to="/">
            <GatsbyLogo />
          </Link>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <MobileMenu />
        </Navbar.Collapse>
        <Menu />
      </Navbar>
      
    </Row>
  </Container>
</header>
)
