import React from "react"
import Header from "./header"
import Footer from "./footer"
import SEO from "./seo"
import useSiteMetadata from "../hooks/use-site-metadata"
import "../assets/style.scss"


const Layout = ({ children }) => {
const { title, metaDescription  } = useSiteMetadata();

  return (
    <div>
      <SEO title={title} description={metaDescription} />
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout
