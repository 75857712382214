import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styles from "../assets/scss/regions/menu.module.scss"
import { normalizePath } from "../utils/get-url-path"

export default () => {
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "main" }) {
        name
        menuItems {
          nodes {
            label
            url
            connectedObject {
              ... on WpContentNode {
                uri
              }
            }
          }
        }
      }
    }
  `)

  return !!wpMenu && !!wpMenu.menuItems && !!wpMenu.menuItems.nodes ? (
    <ul className={styles.desktopMenu}>
    {wpMenu.menuItems.nodes.map((menuItem, i) => {
      const path = menuItem?.connectedObject?.uri ?? menuItem.url

      return (
        <li>
          <Link to={normalizePath(path)} key={i + menuItem.url}>
              {menuItem.label}
          </Link>
        </li>
      )
    })}
    </ul>
  ) : null
}
