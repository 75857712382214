//https://www.youtube.com/watch?v=asrdFuAxPaU&list=PLz8Iz-Fnk_eTpvd49Sa77NiF8Uqq5Iykx

import { graphql, useStaticQuery } from 'gatsby';

const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
      wpPage {
        seo {
          title
          metaDesc
          metaKeywords
          focuskw
        }
      }
    }
  `);

  return data.wpPage.seo;
};

export default useSiteMetadata;