import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
// import { Menu, Button, Grid, Box } from "@chakra-ui/core"
import { normalizePath } from "../utils/get-url-path"

export default () => {
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "footer" }) {
        name
        menuItems {
          nodes {
            label
            url
            connectedObject {
              ... on WpContentNode {
                uri
              }
            }
          }
        }
      }
    }
  `)

  return !!wpMenu && !!wpMenu.menuItems && !!wpMenu.menuItems.nodes ? (
    <div>
      <div>
        <div>
          {wpMenu.menuItems.nodes.map((menuItem, i) => {
            const path = menuItem?.connectedObject?.uri ?? menuItem.url

            return (
              <Link style={{ display: `block` }} to={normalizePath(path)}>
                <div key={i + menuItem.url}>
                  {menuItem.label}
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  ) : null
}
