import React from "react"
// import { div, footer } from "@chakra-ui/core"
import FooterMenu from "./footer-menu"
import styles from "../assets/scss/regions/footer.module.scss"

export default () => (
  
<footer className={styles.footer}>
  <div>
    <FooterMenu />
  </div>
</footer>
)
